// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $font-family-base: "Helvetica Neue LT W01_71488914","Helvetica",sans-serif;

$nav-link: "text-gray-900";

.bg-green {
    background-color: #C2E7DA;
  };

  .bg-blue {
    background-color: #6290c3;
    color: white;
  };
  
  .bg-lime {
    background-color: #BAFF29;
  };
  
  .bg-melon {
    background-color: #F2FFD6;
  };

  .bg-solid {
    background-color: #388659;
  };
